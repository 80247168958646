import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from 'src/app/modules/shared/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: LoggerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      return this.authService.doAuthenticationCheck().then(
        () => {
          return this.authService.isAuthenticated();
        },
        (err) => {
          this.logger.error('Error while doing authentication check', { error: err });
          return false;
        }
      );
    } catch (err) {
      this.logger.error('Error while doing authentication check', { error: err });
      return false;
    }
  }
}
