import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DossierDetail, DossierFilter, DossierHistoryModel, PagedDossier } from 'src/app/model';
import { LoggerService } from '../../shared/services/logger.service';
import { QueryStateService } from '../../shared/services/query-state.service';
import { ClientModel } from '../../store/client/client.model';
import { LogLevel } from '../../shared/services/logger.service';

@Injectable()
export class DossierService {
  private dossiersUrl = 'dossiers';

  constructor(
    private http: HttpClient,
    private queryStateService: QueryStateService,
    private store: Store,
    private logger: LoggerService
  ) {}

  async getDossierPaymentPlanHistory(dossierId: string): Promise<DossierHistoryModel> {
    const queryScope = this.queryStateService.addClientParameters({});
    return this.http
      .get<DossierHistoryModel>(`${this.dossiersUrl}/${dossierId}/paymentplan/history`, {
        params: queryScope as any,
      })
      .toPromise();
  }

  async getDossierHistory(dossierId: string): Promise<DossierHistoryModel> {
    const queryScope = this.queryStateService.addClientParameters({});
    return this.http
      .get<DossierHistoryModel>(`${this.dossiersUrl}/${dossierId}/history`, {
        params: queryScope as any,
      })
      .toPromise();
  }

  async getDossierDetail(dossierId: string): Promise<DossierDetail> {
    const currentClientModel = this.store.selectSnapshot<ClientModel>((state) => state.client);
    let queryScope;

    try {
      // If we're at the group level (clientIds includes '*'), maintain that context
      if (currentClientModel?.clientIds?.includes('*')) {
        queryScope = { clientGroupIds: currentClientModel.clientGroupIds };
        this.logger.debug('Using group-level context for dossier access', {
          dossierId,
          clientGroupIds: currentClientModel.clientGroupIds,
        });
      } else {
        // Otherwise use the standard client parameters
        queryScope = this.queryStateService.addClientParameters({});
        this.logger.debug('Using client-level context for dossier access', {
          dossierId,
          clientIds: currentClientModel?.clientIds,
        });
      }

      return await this.http
        .get<DossierDetail>(`${this.dossiersUrl}/${dossierId}`, { params: queryScope as any })
        .toPromise();
    } catch (error) {
      this.logger.error('Error accessing dossier', {
        dossierId,
        queryScope,
        error: error.message,
      });
      throw error;
    }
  }

  async updateDossierFlowToDebtCollection(invoiceIds: Set<string>): Promise<any> {
    const invoiceIdsArray = Array.from(invoiceIds).map((id) => id.toString());
    return await this.http
      .patch(`${this.dossiersUrl}/transfer-debtcollection`, invoiceIdsArray, {
        headers: { 'Content-Type': 'application/json' },
      })
      .toPromise();
  }

  async addOngoingInvoicesToCurrentDossier(
    invoiceIds: Set<string>,
    dossierId: string
  ): Promise<any> {
    const invoiceIdsArray = Array.from(invoiceIds).map((id) => id.toString());
    return this.http
      .patch(`${this.dossiersUrl}/add-ongoing-invoices-to-dossier/${dossierId}`, invoiceIdsArray, {
        headers: { 'Content-Type': 'application/json' },
      })
      .toPromise();
  }

  async linkDebtorToDossier(dossierId: string, debtorId: string): Promise<any> {
    const queryScope = this.queryStateService.addClientParameters({ debtorId } as any);
    return this.http
      .patch(`${this.dossiersUrl}/${dossierId}/link-debtor-to-dossier`, null, {
        observe: 'response',
        params: queryScope as any,
      })
      .toPromise();
  }

  async deleteDossier(dossierId: string): Promise<any> {
    const params = this.queryStateService.addClientParameters({} as any) as any;
    return this.http
      .request('delete', this.dossiersUrl, { body: { dossierIds: [dossierId] }, params })
      .toPromise();
  }

  async getDossiers(filter: DossierFilter): Promise<PagedDossier> {
    const queryScope = this.queryStateService.addClientParameters(filter);
    const result = await this.http
      .get<PagedDossier>(`${this.dossiersUrl}`, { params: queryScope as any })
      .toPromise();

    // Log flow information for debugging
    if (result?.dossiers?.length > 0 && this.logger.isEnabled(LogLevel.Debug)) {
      const sample = result.dossiers[0];
      this.logger.debug('Flow information from backend', {
        count: result.dossiers.length,
        sampleDossierId: sample.id,
        primaryFlow: sample.primaryFlow,
        flowsCount: sample.invoiceFlows?.length || 0,
      });
    }

    return result;
  }

  public async resetFlow(dossierId: string, skipImmediateAction: boolean) {
    const payload = this.queryStateService.addClientParameters({
      dossierIds: [dossierId],
      resetScheduleReferenceDate: true,
      skipImmediateAction,
      resetSkipImmediate: true,
    } as any) as any;
    return this.http.put(`${this.dossiersUrl}/reset`, payload).toPromise();
  }

  getDossierIdFromUrl(): string | null {
    const url = window.location.href;
    const segments = url.split('/');

    // Only return the ID if we're in an dossier route
    if (url.includes('/dossiers/')) {
      return segments[segments.length - 1];
    }

    return null;
  }
}
