import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  CtpActionFailingNotificationMetadata,
  NotificationDto,
  NotificationFilter,
  NotificationType,
  PaginationNotificationDto,
  PlannedActionPausedNotificationMetadata,
  PlannedActionState,
  PlannedActionType,
  ProtestNotificationMetadata,
  ReminderNoteNotificationMetadata,
} from 'src/app/model';
import { EventNotificationMetadata } from 'src/app/model/dtos/notifications/metadata/event-notification-metadata';
import { SupportNotificationMetadata } from 'src/app/model/dtos/notifications/metadata/support-notification-metadata';
import { QueryStateService } from '../../shared/services';
import { LoggerService } from '../../shared/services/logger.service';
import { SetOverviewNotifications } from '../../store';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationUrl = 'notification';
  private newNotification: BehaviorSubject<NotificationDto> = new BehaviorSubject<NotificationDto>(
    null
  );
  public notificationStateUpdated: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  constructor(
    private http: HttpClient,
    private queryStateService: QueryStateService,
    private router: Router,
    private store: Store,
    private logger: LoggerService
  ) {}

  public getNotifications(filter: NotificationFilter): Observable<PaginationNotificationDto> {
    const queryScope = this.queryStateService.addClientParameters(filter);
    return this.http
      .get<PaginationNotificationDto>(this.notificationUrl, { params: queryScope as any })
      .pipe(
        catchError((error) => {
          this.logger.error('Error fetching notifications', { error });
          return of(null);
        })
      );
  }

  public updateNotificationIsReadState(notificationIds: string[], isRead: boolean) {
    return this.http
      .patch<void>(`${this.notificationUrl}/state`, notificationIds, { params: { isRead } as any })
      .subscribe(
        () => {
          this.notificationStateUpdated.next(notificationIds);
          this.logger.debug('Successfully updated notification state', { notificationIds, isRead });
        },
        (error) => {
          this.logger.error('Error updating notification state', {
            error,
            notificationIds,
            isRead,
          });
        }
      );
  }

  public async navigateAfterNotificationClicked(notification: NotificationDto) {
    try {
      switch (notification.type) {
        case NotificationType.ReminderNote:
          const reminderNoteData = notification.metadata as ReminderNoteNotificationMetadata;
          if (reminderNoteData.debtorId) {
            await this.router.navigate(['main', 'debtors', reminderNoteData.debtorId]);
          } else if (reminderNoteData.invoiceId) {
            await this.router.navigate(['main', 'invoices', reminderNoteData.invoiceId]);
          } else if (reminderNoteData.dossierId) {
            await this.router.navigate(['main', 'dossiers', reminderNoteData.dossierId]);
          }
          break;
        case NotificationType.ClientAdmin:
          const clientAdminData = notification.metadata as SupportNotificationMetadata;
          if (clientAdminData.debtorId) {
            await this.router.navigate(['main', 'debtors', clientAdminData.debtorId]);
          } else if (clientAdminData.invoiceId) {
            await this.router.navigate(['main', 'invoices', clientAdminData.invoiceId]);
          } else if (clientAdminData.dossierId) {
            await this.router.navigate(['main', 'dossiers', clientAdminData.dossierId]);
          }
          break;
        case NotificationType.Support:
          const supportData = notification.metadata as SupportNotificationMetadata;
          if (supportData.debtorId) {
            await this.router.navigate(['main', 'debtors', supportData.debtorId]);
          } else if (supportData.invoiceId) {
            await this.router.navigate(['main', 'invoices', supportData.invoiceId]);
          } else if (supportData.dossierId) {
            await this.router.navigate(['main', 'dossiers', supportData.dossierId]);
          }
          break;
        case NotificationType.Protest:
          await this.router.navigate([
            'main',
            'invoices',
            (notification.metadata as ProtestNotificationMetadata).invoiceId,
          ]);
          break;
        case NotificationType.CtpActionFailing:
          const metaData = notification.metadata as CtpActionFailingNotificationMetadata;
          // Navigating to creditnote not yet supported
          if (metaData.invoiceId) {
            await this.router.navigate(['main', 'invoices', metaData.invoiceId]);
          } else if (metaData.dossierId) {
            await this.router.navigate(['main', 'dossiers', metaData.dossierId]);
          }
          break;
        case NotificationType.PlannedActionIsPaused:
          const actionData = notification.metadata as PlannedActionPausedNotificationMetadata;
          if (actionData.plannedActionId) {
            await this.router.navigate(['main', 'actions'], {
              queryParams: {
                actionId: actionData.plannedActionId,
                actionClientId: actionData.clientId,
                currentType: PlannedActionType.Invoice,
                currentActionsType: PlannedActionState.Paused,
              },
              queryParamsHandling: 'merge',
            });
          }
          break;
        case NotificationType.Event:
          const eventData = notification.metadata as EventNotificationMetadata;
          if (eventData.creditNoteId) {
            await this.router.navigate(['main', 'creditnotes', eventData.creditNoteId]);
          } else if (eventData.invoiceId) {
            await this.router.navigate(['main', 'invoices', eventData.invoiceId]);
          } else if (eventData.dossierId) {
            await this.router.navigate(['main', 'dossiers', eventData.dossierId]);
          }
          break;
        default:
          this.logger.warn('Unknown notification type', { type: notification.type });
          break;
      }
    } catch (error) {
      this.logger.error('Error navigating after notification click', { error, notification });
    }
  }

  public setFilterNotification(notificationFilter: NotificationFilter) {
    notificationFilter.isImportant = true;
    this.getNotifications(notificationFilter).subscribe({
      next: (result) => {
        if (result) {
          this.store.dispatch(
            new SetOverviewNotifications(result.notifications, result.totalCount)
          );
        } else {
          this.logger.error('No result returned from getNotifications');
        }
      },
      error: (error) => {
        this.logger.error('Error fetching notifications', { error });
      },
    });
  }

  public setNotifications(notificationFilter: NotificationFilter = null) {
    if (notificationFilter) {
      this.setFilterNotification(notificationFilter);
      return;
    } else {
      this.setFilterNotification(new NotificationFilter(1, 10, 'date', 'desc'));
    }
  }

  public setNewNotification(notification: NotificationDto) {
    this.newNotification.next(notification);
  }

  public getNewNotification(): Observable<NotificationDto> {
    return this.newNotification.asObservable();
  }
}
