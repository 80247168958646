<!-- Payment simulation content - nested inside a card in parent -->
<div class="payment-container">
  <!-- Payment information section -->
  <div class="payment-section">
    <div class="payment-text">
      {{
        'PAYMENTPLAN.SIMULATION_EXPLANATION'
          | translate
            : {
                intervalText:
                  (selection.interval === intervalEnum.Weekly
                    ? 'PAYMENTPLAN.WEEKLY'
                    : 'PAYMENTPLAN.MONTHLY'
                  )
                  | translate
                  | lowercase,
                payAmount: suggestion.items[0].amount.amountToBePaid | currencyFormat,
                startDate:
                  suggestion.items[0].paymentDueDate | amLocale : 'nl-be' | amDateFormat : 'L'
              }
      }}
    </div>
    <div
      *ngIf="
        suggestion.items[0].amount.amountToBePaid !==
        suggestion.items[suggestion.items.length - 1].amount.amountToBePaid
      "
      class="payment-warning"
    >
      {{
        'PAYMENTPLAN.ATTENTION'
          | translate
            : {
                payAmount:
                  suggestion.items[suggestion.items.length - 1].amount.amountToBePaid
                  | currencyFormat,
                payDate:
                  suggestion.items[suggestion.items.length - 1].paymentDueDate
                  | amLocale : 'nl-be'
                  | amDateFormat : 'L'
              }
      }}
    </div>
  </div>

  <!-- Payment details section -->
  <div class="payment-details">
    <app-payment-plan-detail
      *ngIf="suggestion"
      [paymentPlan]="suggestion"
      [alwaysShowPaymentTable]="true"
      [inCreation]="true"
    ></app-payment-plan-detail>
  </div>
</div>
