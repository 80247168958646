import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { ConfirmDialog, PaymentPlan, PaymentPlanInterval, Permission } from 'src/app/model';
import { PaymentPlanService } from 'src/app/modules/main/services/paymentplan.service';

@Component({
  selector: 'app-payment-plan-detail',
  templateUrl: './paymentplan-detail.component.html',
  styleUrls: ['./paymentplan-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentPlanDetailComponent implements OnChanges {
  @Input()
  paymentPlan: PaymentPlan;

  @Input()
  alwaysShowPaymentTable = false;

  @Input()
  inCreation = false; // Will hide irrelevant info during creation when true

  @Output()
  paymentPlanDeleted = new EventEmitter<string>();

  showPaymentTable = false;
  deleting = false;
  PaymentInterval = PaymentPlanInterval;
  Permission = Permission;

  constructor(
    private paymentPlanService: PaymentPlanService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  public async ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line: no-string-literal
    if (
      changes['paymentPlan'] &&
      changes['paymentPlan'].currentValue !== changes['paymentPlan'].previousValue
    ) {
      this.paymentPlan.items = orderBy(this.paymentPlan.items, (x) => x.counter);
    }
  }

  async deletePaymentPlan(confirmDialog: ConfirmDialog) {
    if (this.deleting) {
      return;
    }
    this.deleting = true;
    const response = await this.paymentPlanService.deletePaymentPlan(
      this.paymentPlan.id,
      confirmDialog.optionalNote
    );
    if (response.ok) {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('INVOICE.DELETE_PAYMENTPLAN.SUCCESS'),
        detail: this.translateService.instant('INVOICE.DELETE_PAYMENTPLAN.SUCCESSDETAIL'),
        life: 5000,
      });

      // Store the payment plan ID before setting it to undefined
      const paymentPlanId = this.paymentPlan.id;
      this.paymentPlan = undefined;

      // Emit the event with the payment plan ID to notify the parent component
      this.paymentPlanDeleted.emit(paymentPlanId);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('INVOICE.DELETE_PAYMENTPLAN.ERROR'),
        detail: this.translateService.instant('INVOICE.DELETE_PAYMENTPLAN.ERRORDETAIL'),
        life: 5000,
      });
    }
    this.deleting = false;
  }

  getSignedPaymentPlanUrl(paymentPlanId: string) {
    return this.paymentPlanService.getPaymentPlanPdfUrl(paymentPlanId);
  }
}
