import { Component, EventEmitter, Input, Output, LOCALE_ID, Inject } from '@angular/core';
import { CustomDatePickerService } from './../../services/custom-datepicker.service';
import { getLocaleFirstDayOfWeek, formatDate } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
})
export class CustomDatepickerComponent {
  @Input() value: Date;
  @Output() valueChange: EventEmitter<Date>; // By convention ...Change facilitates two way binding aka banana in the box
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() placeholder: string;
  @Input() inputStyleClass: string;

  constructor(
    private customDatePickerService: CustomDatePickerService,
    @Inject(LOCALE_ID) private localeId: string,
    private config: PrimeNGConfig
  ) {
    this.valueChange = new EventEmitter<Date>();
    this.initLocaleSettings();
  }

  private initLocaleSettings() {
    this.config.setTranslation({
      ...this.customDatePickerService.locale,
      firstDayOfWeek: getLocaleFirstDayOfWeek(this.localeId),
    });
  }

  dateWasUpdated() {
    this.valueChange.emit(this.value);
  }
}
