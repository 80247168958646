import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
  None = 4,
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  // Default to Error only in production, all in development
  private logLevel: LogLevel = environment.production ? LogLevel.Error : LogLevel.Debug;

  setLogLevel(level: LogLevel) {
    this.logLevel = level;
  }

  debug(message: string, context?: Record<string, any>) {
    if (this.logLevel <= LogLevel.Debug && !environment.production) {
      console.debug(`[DEBUG] ${message}`, context);
    }
  }

  info(message: string, context?: Record<string, any>) {
    if (this.logLevel <= LogLevel.Info && !environment.production) {
      console.info(`[INFO] ${message}`, context);
    }
  }

  warn(message: string, context?: Record<string, any>) {
    if (this.logLevel <= LogLevel.Warn && !environment.production) {
      console.warn(`[WARN] ${message}`, context);
    }
  }

  error(message: string, context?: Record<string, any>) {
    if (this.logLevel <= LogLevel.Error) {
      if (environment.production) {
        // In production, only send to Sentry
        Sentry.captureException(new Error(message), {
          level: 'error',
          extra: {
            ...context,
            timestamp: new Date().toISOString(),
          },
        });
      } else {
        // In development, show in console
        console.error(`[ERROR] ${message}`, context);
      }
    }
  }

  // Helper to check if a particular level is enabled
  isEnabled(level: LogLevel): boolean {
    return (
      (!environment.production && this.logLevel <= level) ||
      (environment.production && level === LogLevel.Error)
    );
  }
}
