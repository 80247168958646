import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GeneralModel } from './modules/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select((state) => state.general) general$: Observable<GeneralModel>;
  private generalStateSubscription: Subscription;

  constructor(public translate: TranslateService, private title: Title) {
    translate.addLangs(['nl-be', 'fr-be', 'en-be']);
    // Set default here, setting chosen language should happen with PreferenceService (happens on login in AuthService)
    // External components should handle setting the language themselves
    translate.setDefaultLang('nl-be');

    this.generalStateSubscription = this.general$.subscribe((model: GeneralModel) => {
      if (!model || !model.pageTitle) {
        this.title.setTitle('Sales2Cash');
        return;
      }
      this.translate.get(model.pageTitle, model.titleParams).subscribe((result) => {
        this.title.setTitle('Sales2Cash | ' + result);
      });
    });
  }

  ngOnInit() {
    // Initialize favicon from localStorage on application startup
    this.initializeFavicon();
  }

  ngOnDestroy() {
    this.generalStateSubscription.unsubscribe();
  }

  /**
   * Initialize favicon from localStorage at application startup
   */
  private initializeFavicon(): void {
    // Try to get cached icon URL from localStorage
    const cachedIconUrl = localStorage.getItem('clientIconUrl');
    if (cachedIconUrl) {
      // Update favicon if we have a cached URL
      this.updateFavicon(cachedIconUrl);
    }
  }

  /**
   * Updates the favicon
   * @param iconUrl The URL of the icon to use
   */
  private updateFavicon(iconUrl: string): void {
    if (!iconUrl) return;

    // Find existing favicon by ID or fallback to query selector
    let link: HTMLLinkElement = document.getElementById('favicon-link') as HTMLLinkElement;
    if (!link) {
      // Fallback to query selector for backward compatibility
      link = document.querySelector('link[rel*="icon"]');
      if (!link) {
        link = document.createElement('link');
        link.rel = 'shortcut icon';
        link.id = 'favicon-link';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }

    // Set the href to update the favicon
    link.href = iconUrl;
  }
}
