import { UserManager, WebStorageStateStore, Log, Logger } from 'oidc-client';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../services/logger.service';
import { Injectable } from '@angular/core';

// Custom OIDC Logger implementation that uses our LoggerService
class OidcLoggerImpl implements Logger {
  private loggerService: LoggerService;

  constructor(loggerService: LoggerService) {
    this.loggerService = loggerService;
  }

  debug(message: string): void {
    this.loggerService.debug(`[OIDC] ${message}`);
  }

  info(message: string): void {
    this.loggerService.info(`[OIDC] ${message}`);
  }

  warn(message: string): void {
    this.loggerService.warn(`[OIDC] ${message}`);
  }

  error(message: string): void {
    this.loggerService.error(`[OIDC] ${message}`);
  }
}

@Injectable({
  providedIn: 'root',
})
export class OpenIdConnectService {
  private userManager: UserManager;

  constructor(private logger: LoggerService) {
    this.userManager = this.createUserManager();
  }

  public getUserManager(): UserManager {
    return this.userManager;
  }

  private createUserManager(): UserManager {
    // Set up custom logger implementation
    Log.logger = new OidcLoggerImpl(this.logger);
    Log.level = Log.INFO;

    const config: any = {
      authority: environment.authEndpoint,
      client_id: 'spaclient',
      redirect_uri: environment.appUri,
      post_logout_redirect_uri: environment.appUri,
      response_type: 'id_token token',
      scope: 'openid profile email satocaapi.access',
      silent_redirect_uri: environment.appUri + 'auth.html',
      silentRequestTimeout: 3000,
      automaticSilentRenew: true,
      loadUserInfo: true,
      clockSkew: 60, // 1 minute clock skew is allowed
      filterProtocolClaims: true,
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    const manager = new UserManager(config);

    // Log errors for debugging
    manager.events.addSilentRenewError((error) => {
      try {
        if (this && this.logger) {
          this.logger.error('Silent renew error', error);
        } else {
          // Fallback if service is not available
          this.logger.error('Silent renew error (logger fallback):', error);
        }
      } catch (err) {
        this.logger.error('Error handling silent renew error:', err);
      }
    });

    manager.events.addAccessTokenExpired(() => {
      try {
        if (this && this.logger) {
          this.logger.debug('Access token expired');
        } else {
          // Fallback if service is not available
          this.logger.debug('Access token expired (logger fallback)');
        }
      } catch (error) {
        this.logger.error('Error logging token expiration:', error);
      }
    });

    return manager;
  }
}
