import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { PagedFilterModel } from 'src/app/model';

@Injectable()
export class TableFilterService {
  private searchQueryUpdated = new EventEmitter<string>();
  private minAmountUpdated = new EventEmitter<string>();
  private maxAmountUpdated = new EventEmitter<string>();

  constructor(private router: Router) {
    this.searchQueryUpdated.pipe(debounceTime(500)).subscribe(async (searchQuery) => {
      await this.router.navigate([], {
        queryParams: { searchQuery: searchQuery === '' ? undefined : searchQuery },
        queryParamsHandling: 'merge',
      });
    });
    this.minAmountUpdated.pipe(debounceTime(500)).subscribe(async (amount) => {
      await this.router.navigate([], {
        queryParams: { minAmount: amount === '' ? undefined : amount },
        queryParamsHandling: 'merge',
      });
    });
    this.maxAmountUpdated.pipe(debounceTime(500)).subscribe(async (amount) => {
      await this.router.navigate([], {
        queryParams: { maxAmount: amount === '' ? undefined : amount },
        queryParamsHandling: 'merge',
      });
    });
  }

  public getTableState<TFilterModel extends PagedFilterModel>(
    tableIdentifier: string,
    type: {
      new (
        pageNumber: number,
        pageSize: number,
        orderBy: string,
        orderDirection: string,
        clientId?: string,
        clientGroupId?: string,
        skipClientFilter?: boolean
      ): TFilterModel;
    },
    skipClientFilter: boolean = false
  ): TFilterModel {
    // First check for a global pageSize preference, then fall back to table-specific value
    const globalPageSize = parseInt(localStorage.getItem('global.tablePageSize'), 10);
    const tablePageSize = parseInt(localStorage.getItem(tableIdentifier + '.pageSize'), 10);
    const pageSize = globalPageSize || tablePageSize || 10;

    const filter = new type(
      1,
      pageSize,
      localStorage.getItem(tableIdentifier + '.orderBy') || '',
      localStorage.getItem(tableIdentifier + '.orderDirection') || 'asc',
      undefined,
      undefined,
      skipClientFilter
    );

    // Set both pageSize and take to ensure compatibility with all components
    if ('pageSize' in filter) {
      (filter as any).pageSize = pageSize;
    }
    filter.take = pageSize;

    return filter;
  }

  /**
   * Get the table state without applying client filtering
   */
  public getTableStateWithoutClientFilter<TFilterModel extends PagedFilterModel>(
    tableIdentifier: string,
    type: {
      new (
        pageNumber: number,
        pageSize: number,
        orderBy: string,
        orderDirection: string,
        clientId?: string,
        clientGroupId?: string,
        skipClientFilter?: boolean
      ): TFilterModel;
    }
  ): TFilterModel {
    return this.getTableState(tableIdentifier, type, true);
  }

  public updateTableFilter(
    tableIdentifier: string,
    filter: PagedFilterModel,
    tableUpdateEvent: any
  ): boolean {
    const orderBy = tableUpdateEvent.sortField;
    const skip = tableUpdateEvent.first;
    const take = tableUpdateEvent.rows;
    const orderAscending = tableUpdateEvent.sortOrder === 1;

    // Only when different sorting or paging is applied, the actual filter object gets updated and stored
    if (!this.filterNeedsUpdate(filter, orderBy, skip, take, orderAscending)) {
      return false;
    }

    filter.orderBy = orderBy;
    filter.skip = skip;
    filter.take = take;
    filter.orderAscending = orderAscending;

    // Set pageSize if the filter has that property
    if ('pageSize' in filter) {
      (filter as any).pageSize = take;
    }

    // Store updated filter - both table-specific and global
    localStorage.setItem(tableIdentifier + '.pageSize', filter.take.toString());
    localStorage.setItem('global.tablePageSize', filter.take.toString());
    localStorage.setItem(tableIdentifier + '.orderBy', filter.orderBy);
    localStorage.setItem(
      tableIdentifier + '.orderDirection',
      filter.orderAscending ? 'asc' : 'desc'
    );

    return true;
  }

  private filterNeedsUpdate(
    filter: PagedFilterModel,
    orderBy: string,
    skip: number,
    take: number,
    orderAscending: boolean
  ): boolean {
    const isSame =
      filter.orderBy === orderBy &&
      filter.skip === skip &&
      filter.take === take &&
      filter.orderAscending === orderAscending;
    return !isSame;
  }

  public resetTableFilter(tableIdentifier: string) {
    // Store reset filter
    localStorage.setItem(tableIdentifier + '.pageSize', '10');
    localStorage.setItem(tableIdentifier + '.orderBy', '');
    localStorage.setItem(tableIdentifier + '.orderDirection', 'asc');
  }

  fromDateChange(date: Date) {
    this.router.navigate([], {
      queryParams: { fromDate: date && date.toDateString() },
      queryParamsHandling: 'merge',
    });
  }

  toDateChange(date: Date) {
    this.router.navigate([], {
      queryParams: { toDate: date && date.toDateString() },
      queryParamsHandling: 'merge',
    });
  }

  onMinAmountChange(amount: string) {
    this.minAmountUpdated.emit(amount);
  }

  onMaxAmountChange(amount: string) {
    this.maxAmountUpdated.emit(amount);
  }

  updateSearchQuery(newValue: string) {
    this.searchQueryUpdated.emit(newValue);
  }
}
