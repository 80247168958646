import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { FormlyConfig, FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as iban from 'iban';
import { PhoneNumberDto } from 'src/app/model';
import { SearchFilter } from 'src/app/model/filters/search-filter.model';
import { Field } from './helpers/fields';
import { UiFormModule } from './ui-form.module';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Injectable({
  providedIn: UiFormModule,
})
export class FormlyService {
  constructor(private translate: TranslateService, private formlyConfig: FormlyConfig) {}

  public static AddIconLeft(
    config: FormlyFieldConfig,
    iconClass: string,
    onClick?: (
      templateOptions: FormlyTemplateOptions,
      formlyWrapperAddons?: any,
      mouseEvent?: MouseEvent
    ) => void
  ): FormlyFieldConfig {
    config.templateOptions.addonLeft = {
      class: iconClass + ' font-weight-bold',
      onClick,
    };
    return config;
  }

  public static AddIconRight(
    config: FormlyFieldConfig,
    iconClass: string,
    onClick?: (
      templateOptions: FormlyTemplateOptions,
      formlyWrapperAddons?: any,
      mouseEvent?: MouseEvent
    ) => void
  ): FormlyFieldConfig {
    config.templateOptions.addonRight = {
      class: iconClass,
      onClick,
    };
    return config;
  }

  public static HideWhen(config: FormlyFieldConfig, hideExpression: string): FormlyFieldConfig {
    config.hideExpression = hideExpression;
    return config;
  }

  private static togglePasswordVisibility(
    templateOptions: FormlyTemplateOptions,
    formlyWrapperAddons?: any,
    event?: MouseEvent
  ) {
    if (templateOptions.type === 'password') {
      templateOptions.type = 'text';
      templateOptions.addonRight.class = 'far fa-eye-slash';
    } else {
      templateOptions.type = 'password';
      templateOptions.addonRight.class = 'far fa-eye';
    }
  }

  public addShouldNotBeGreaterThanValidator(
    validatorName: string,
    validationErrorMessage: string,
    fieldName: string,
    referenceFieldName: string
  ) {
    this.formlyConfig.addValidatorMessage(validatorName, (err, field) =>
      this.translate.instant(validationErrorMessage)
    );
    this.formlyConfig.setValidator({
      name: validatorName,
      validation: (c: UntypedFormControl): ValidationErrors | null => {
        const formGroup = c.parent;
        if (!formGroup) {
          return null;
        }
        const a = formGroup.controls[fieldName];
        const b = formGroup.controls[referenceFieldName];
        if (!a || !b || !a.value || !b.value) {
          return null;
        }
        const error = {};
        error[validatorName] = true;
        return a.value > b.value ? error : null;
      },
    });
  }

  public addAtLeastOneDefaultContactPersonValidator(validatorName: string, fieldName: string) {
    this.formlyConfig.addValidatorMessage(validatorName, () =>
      this.translate.instant('FORM.VALIDATION.ONE_CONTACTPERSON')
    );
    this.formlyConfig.setValidator({
      name: validatorName,
      validation: (c: UntypedFormControl): ValidationErrors | null => {
        const formGroup = c.parent;
        if (!formGroup) {
          return null;
        }
        const contactPersons = formGroup.controls[fieldName];
        if (
          !contactPersons ||
          !contactPersons.value ||
          contactPersons.value.filter((x) => x.isDefaultContactPerson).length !== 1
        ) {
          const error = {};
          error[validatorName] = true;
          return error;
        }
        return null;
      },
    });
  }

  createHorizontalLine(wrapWithDiv = false): FormlyFieldConfig {
    return wrapWithDiv
      ? ({
          template: '<div style="width: 100%; height: 2px; color: gray;"><hr/></div>',
        } as FormlyFieldConfig)
      : ({
          template: '<p><hr style="width: 100%; height: 2px; color: gray;" /></p>',
        } as FormlyFieldConfig);
  }

  createImagePreview(url): FormlyFieldConfig {
    return url != null
      ? ({
          template: `<div class="col mt-3 mb-1"><img src="${url}"></div>`,
        } as FormlyFieldConfig)
      : {};
  }

  createTitle(title: string): FormlyFieldConfig {
    return {
      template: `<div class="col"><h5>${title}</h5></div>`,
    } as FormlyFieldConfig;
  }

  createExplainingLabel(content: string): FormlyFieldConfig {
    return {
      template: `<span class="col light-small-text">${content}</span>`,
    } as FormlyFieldConfig;
  }

  createWarningLabel(content: string, showWarning: boolean): FormlyFieldConfig {
    if (showWarning) {
      return {
        template: `<span class="col text-danger" >${content}</span>`,
      } as FormlyFieldConfig;
    }
    return {} as FormlyFieldConfig;
  }

  createDownloadLink(message: string, href: string): FormlyFieldConfig {
    return {
      template: `<div class="col">
          <a target="_blank" href="${href}"><i class="fas fa-download mr-2"></i>${this.translate.instant(
        message
      )}</a>
        </div>`,
    } as FormlyFieldConfig;
  }

  createDropdownFromEnum(
    key: string,
    label: string,
    itemEnum: any,
    excluded: number[],
    translatePrefix: string,
    cssClass: string
  ): FormlyFieldConfig {
    // Convert the enum to a list of translated labels and corresponding int values
    const dropdownItems = Object.keys(itemEnum)
      .filter((x) => !isNaN(Number(itemEnum[x])) && excluded.indexOf(itemEnum[x]) === -1)
      .map((x) => ({
        label: this.translate.instant(`${translatePrefix.toUpperCase()}.${x.toUpperCase()}`),
        value: itemEnum[x],
      }));

    const templateOptions = {
      key,
      required: true,
      options: dropdownItems,
    } as FormlyTemplateOptions;

    // Somehow setting required to true doesn't do the trick for a select
    const validations = {
      validators: {
        req: {
          expression: (c) => c.value > -1,
          message: this.translate.instant('FORM.VALIDATION.REQUIRED'),
        },
      },
    };

    const formItem = Field.select(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  createDropdownFromArray(
    key: string,
    label: string,
    itemsArray: string[],
    itemEnum: any,
    excluded: string[],
    cssClass: string
  ): FormlyFieldConfig {
    // Convert the array of strings to a list of translated labels and corresponding string values
    const dropdownItems = itemsArray
      .filter((item) => excluded.indexOf(item) === -1)
      .map((item) => ({
        label: item,
        value: itemsArray.indexOf(item),
      }));

    const templateOptions = {
      key,
      required: true,
      options: dropdownItems,
    } as FormlyTemplateOptions;

    // Somehow setting required to true doesn't do the trick for a select
    const validations = {
      validators: {
        req: {
          expression: (c) => c.value !== null && c.value !== undefined && c.value !== '',
          message: this.translate.instant('FORM.VALIDATION.REQUIRED'),
        },
      },
    };

    const formItem = Field.select(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  createRequiredDropdownFromObject(
    key: string,
    label: string,
    itemObject: any,
    cssClass: string
  ): FormlyFieldConfig {
    // Convert the object to a list
    const dropdownItems = itemObject.map((x) => ({ label: x.name, value: x.id }));

    const templateOptions = {
      key,
      required: true,
      options: dropdownItems,
    } as FormlyTemplateOptions;

    return Field.select(key, cssClass, templateOptions, this.expressionWithTranslatedLabel(label));
  }

  createRequiredAutoCompleteInput(
    key: string,
    label: string,
    cssClass: string,
    searchFunction: (searchFIlter: SearchFilter) => Promise<any>,
    mask?: string,
    prefix?: string,
    suffix?: string,
    validationRegex?: RegExp,
    infoBuilder?: (value: any) => string, // Adds possibility to add pre/suffixes and other crazy stuff
    extraInfoBuilder?: (value: any) => string, // Builds an 'extra info' string based on the value in the autocomplete
    searchKey?: string
  ): FormlyFieldConfig {
    const templateOptions = {
      required: true,
      mask: mask || undefined,
      prefix: prefix || '',
      suffix: suffix || '',
      onPaste: (event) => this.onPaste(event),
      additionalProperties: {
        searchKey: searchKey || key, // When no search key is defined use the model key
        suggestions: undefined,
        searchFunction,
        infoBuilder: infoBuilder ? infoBuilder : (x) => x[key] || x,
        extraInfoBuilder: extraInfoBuilder ? extraInfoBuilder : '',
      },
    } as FormlyTemplateOptions;

    const formItem = Field.autoComplete(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    const validations = validationRegex
      ? {
          validators: {
            inv: {
              expression: (c) => !c.value || validationRegex.test(c.value),
              message: this.translate.instant('FORM.VALIDATION.INVALIDVALUE'),
            },
          },
        }
      : undefined;
    return { ...formItem, ...validations };
  }

  createRequiredPhoneNumberInput(key: string, label: string, cssClass: string): FormlyFieldConfig {
    return this.createPhoneNumberInput(key, label, cssClass, true);
  }

  createPhoneNumberInput(
    key: string,
    label: string,
    cssClass: string,
    isRequired: boolean = false
  ): FormlyFieldConfig {
    const templateOptions = { required: isRequired } as FormlyTemplateOptions;
    const validations = {
      validators: {
        invalid: {
          expression: (c) => {
            if (c.value && !isRequired && (c.value.number == null || c.value.number === '')) {
              return true;
            }
            let isValid = false;
            const numberDto: PhoneNumberDto = c.value;
            if (numberDto) {
              try {
                const parsedNumber = phoneNumberUtil.parse(
                  numberDto.dialCode + numberDto.number,
                  'ZZ'
                );
                isValid = phoneNumberUtil.isValidNumber(parsedNumber);
              } catch {
                isValid = false;
              }
            }
            return !c.value || isValid;
          },
          message: this.translate.instant('FORM.VALIDATION.INVALIDVALUE'),
        },
      },
    };

    const formItem = Field.phoneNumber(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...validations, ...formItem };
  }

  createRequiredInput(key: string, label: string, cssClass: string): FormlyFieldConfig {
    return this.createInputInternal(key, label, cssClass, true);
  }

  createRequiredNumberInput(
    key: string,
    label: string,
    cssClass: string,
    min?: number,
    max?: number,
    extraValidators: string[] = []
  ): FormlyFieldConfig {
    const formItem = this.createInputInternal(key, label, cssClass, true, 'number', min, max);
    return { ...formItem, validators: { validation: extraValidators } };
  }

  createInput(key: string, label: string, cssClass: string): FormlyFieldConfig {
    return this.createInputInternal(key, label, cssClass, false);
  }

  createNumberInput(
    key: string,
    label: string,
    cssClass: string,
    min?: number,
    max?: number,
    extraValidators: string[] = []
  ): FormlyFieldConfig {
    const formItem = this.createInputInternal(key, label, cssClass, false, 'number', min, max);
    return { ...formItem, validators: { validation: extraValidators } };
  }

  private createInputInternal(
    key: string,
    label: string,
    cssClass: string,
    required: boolean,
    type: string = 'text',
    min?: number,
    max?: number
  ): FormlyFieldConfig {
    const templateOptions = {
      key,
      required,
      type,
      min,
      max,
    } as FormlyTemplateOptions;
    return Field.input(key, cssClass, templateOptions, this.expressionWithTranslatedLabel(label));
  }

  createRequiredMask(
    key: string,
    label: string,
    cssClass: string,
    mask: string,
    validationRegex: RegExp,
    prefix?: string,
    suffix?: string
  ): FormlyFieldConfig {
    return this.createMask(key, label, cssClass, mask, validationRegex, prefix, suffix, true);
  }

  createMask(
    key: string,
    label: string,
    cssClass: string,
    mask: string,
    validationRegex: RegExp,
    prefix?: string,
    suffix?: string,
    isRequired: boolean = false
  ): FormlyFieldConfig {
    const templateOptions = {
      key,
      required: isRequired,
      maskString: mask,
      prefix: prefix || '',
      suffix: suffix || '',
      onPaste: (event) => this.onPaste(event),
    } as FormlyTemplateOptions;

    const validations = {
      validators: {
        inv: {
          expression: (c) => !c.value || validationRegex.test(c.value),
          message: this.translate.instant('FORM.VALIDATION.INVALIDVALUE'),
        },
      },
    };

    const formItem = Field.mask(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  createRequiredAccountNumber(key: string, label: string, cssClass: string): FormlyFieldConfig {
    const templateOptions = {
      required: true,
      placeholder: 'BE71 0961 2345 6769',
    } as FormlyTemplateOptions;

    const validations = {
      validators: {
        invalid: {
          expression: (c) => !c.value || iban.isValid(c.value),
          message: this.translate.instant('FORM.VALIDATION.INVALIDVALUE'),
        },
      },
    };

    const formItem = Field.input(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...validations, ...formItem };
  }

  createVatNumberInput(
    key: string,
    label: string,
    cssClass: string,
    isRequired: boolean = true
  ): FormlyFieldConfig {
    const templateOptions = {
      required: isRequired,
      placeholder: 'BE 0123 456 789',
    } as FormlyTemplateOptions;

    //Validations for the vat number form input
    const validations = {
      validators: {
        invalid: {
          // Check if the control exists and the value is a non-empty string
          expression: (c: AbstractControl) =>
            !isRequired || !c.value || (typeof c.value === 'string' && c.value.trim() !== ''),
          message: this.translate.instant('FORM.VALIDATION.INVALIDVALUE'),
        },
      },
    };

    const formItem = Field.input(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...validations, ...formItem };
  }

  createVatNumberAutoComplete(
    key: string,
    label: string,
    cssClass: string,
    searchFunction: (searchFIlter: SearchFilter) => Promise<any>,
    infoBuilder?: (value: any) => string, // Adds possibility to add pre/suffixes and other crazy stuff
    extraInfoBuilder?: (value: any) => string // Builds an 'extra info' string based on the value in the autocomplete
  ): FormlyFieldConfig {
    const templateOptions = {
      required: true,
      placeholder: 'BE 0123 456 789',
      onPaste: (event) => this.onPaste(event),
      additionalProperties: {
        searchKey: key,
        suggestions: undefined,
        searchFunction,
        infoBuilder: infoBuilder ? infoBuilder : (x) => x[key] || x,
        extraInfoBuilder: extraInfoBuilder ? extraInfoBuilder : '',
      },
    } as FormlyTemplateOptions;

    //Validations for the vat number form input
    const validations = {
      validators: {
        invalid: {
          // Check if the control exists and the value is a non-empty string
          expression: (c: AbstractControl) =>
            c && typeof c.value === 'string' && c.value.trim() !== '',
          message: this.translate.instant('FORM.VALIDATION.INVALIDVALUE'),
        },
      },
    };

    const formItem = Field.autoComplete(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...validations, ...formItem };
  }

  createDatePicker(
    key: string,
    label: string,
    cssClass: string,
    mustBeBefore?: Date,
    mustBeAfter?: Date,
    isRequired: boolean = true,
    extraValidators: string[] = []
  ): FormlyFieldConfig {
    const templateOptions = {
      key,
      placeholder: 'dd/mm/yyyy',
      required: isRequired,
      mustBeBefore,
      mustBeAfter,
    } as FormlyTemplateOptions;

    const validations = {
      validators: {
        before: {
          expression: (c) => !c.value || !mustBeBefore || mustBeBefore > c.value,
          message: this.translate.instant('FORM.VALIDATION.MUSTBEBEFORE', {
            before: mustBeBefore
              ? `${mustBeBefore.getDate()}/${
                  mustBeBefore.getMonth() + 1
                }/${mustBeBefore.getFullYear()}`
              : '',
          }),
        },
        after: {
          expression: (c) => !c.value || !mustBeAfter || mustBeAfter <= c.value,
          message: this.translate.instant('FORM.VALIDATION.MUSTBEAFTER', {
            after: mustBeAfter
              ? `${mustBeAfter.getDate()}/${
                  mustBeAfter.getMonth() + 1
                }/${mustBeAfter.getFullYear()}`
              : '',
          }),
        },
        validation: extraValidators,
      },
    };

    const formItem = Field.date(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  createRequiredPassword(key: string, label: string, cssClass: string): FormlyFieldConfig {
    const templateOptions = {
      key,
      required: true,
      minLength: 6,
    } as FormlyTemplateOptions;

    const validations = {
      validators: {
        d: {
          expression: (c) => !c.value || /\d/.test(c.value),
          message: this.translate.instant('FORM.VALIDATION.PASSWORDREQUIRESDIGIT'),
        },
        u: {
          expression: (c) => !c.value || /[A-Z]/.test(c.value),
          message: this.translate.instant('FORM.VALIDATION.PASSWORDREQUIRESUPPERCASECHAR'),
        },
        l: {
          expression: (c) => !c.value || /[a-z]/.test(c.value),
          message: this.translate.instant('FORM.VALIDATION.PASSWORDREQUIRESLOWERCASECHAR'),
        },
      },
    };
    const formItem = Field.password(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return FormlyService.AddIconRight(
      { ...formItem, ...validations },
      'far fa-eye',
      FormlyService.togglePasswordVisibility
    );
  }

  createRequiredMail(key: string, label: string, cssClass: string): FormlyFieldConfig {
    return this.createMail(key, label, cssClass, true);
  }

  createMail(
    key: string,
    label: string,
    cssClass: string,
    isRequired: boolean = false
  ): FormlyFieldConfig {
    const templateOptions = {
      key,
      required: isRequired,
    } as FormlyTemplateOptions;

    const validations = {
      validators: {
        na: {
          // tslint:disable-next-line: max-line-length
          expression: (c) =>
            !c.value ||
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              c.value
            ),
          message: this.translate.instant('FORM.VALIDATION.ENTERVALIDEMAIL'),
        },
      },
    };

    const formItem = Field.email(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  createRequiredLogoUrl(
    key: string,
    placeholder: string,
    label: string,
    cssClass: string
  ): FormlyFieldConfig {
    return this.createLogoUrl(key, placeholder, label, cssClass, true);
  }

  createLogoUrl(
    key: string,
    placeholder: string,
    label: string,
    cssClass: string,
    isRequired: boolean = false
  ): FormlyFieldConfig {
    const templateOptions = {
      key,
      placeholder: placeholder,
      required: isRequired,
    } as FormlyTemplateOptions;

    const validations = {
      validators: {
        na: {
          // tslint:disable-next-line: max-line-length
          expression: (c) => !c.value || /(https?:\/\/.*\.(?:png|jpg))/.test(c.value),
          message: this.translate.instant('FORM.VALIDATION.ENTERVALIDURL'),
        },
      },
    };

    const formItem = Field.input(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  createAgreedRequiredCheckbox(
    key: string,
    label: string,
    cssClass: string,
    errorTranslationKey: string
  ): FormlyFieldConfig {
    return this.createCheckboxInternal(key, label, cssClass, true, errorTranslationKey);
  }

  createCheckbox(key: string, label: string, cssClass: string): FormlyFieldConfig {
    return this.createCheckboxInternal(key, label, cssClass, false);
  }

  private createCheckboxInternal(
    key: string,
    label: string,
    cssClass: string,
    required: boolean,
    validationErrorTranslationKey = ''
  ): FormlyFieldConfig {
    const templateOptions = {
      key,
    };
    let validations = {};
    if (required) {
      validations = {
        validators: {
          na: {
            expression: (c) => c.value,
            message: this.translate.instant(validationErrorTranslationKey),
          },
        },
      };
    }
    const formItem = Field.checkbox(
      key,
      cssClass,
      templateOptions,
      this.expressionWithTranslatedLabel(label)
    );
    return { ...formItem, ...validations };
  }

  private expressionWithTranslatedLabel(label: string) {
    return {
      expressionProperties: {
        'templateOptions.label': this.translate.stream(label),
      },
    };
  }

  createRow(childs: FormlyFieldConfig[]): FormlyFieldConfig {
    const row = {
      fieldGroupClassName: 'row',
      fieldGroup: childs,
    } as FormlyFieldConfig;

    // If no validator messages were set yet, try to do this based on user language
    if (!this.formlyConfig.getValidatorMessage('required')) {
      this.setLanguage();
    }
    return row;
  }

  public setLanguage() {
    this.formlyConfig.addValidatorMessage('required', (err, field) =>
      this.translate.instant('FORM.VALIDATION.REQUIRED')
    );
    this.formlyConfig.addValidatorMessage('minlength', (err, field) =>
      this.translate.instant('FORM.VALIDATION.MINLENGTH', {
        minLength: field.templateOptions.minLength,
      })
    );
    this.formlyConfig.addValidatorMessage('maxlength', (err, field) =>
      this.translate.instant('FORM.VALIDATION.MAXLENGTH', {
        maxLength: field.templateOptions.maxLength,
      })
    );
    this.formlyConfig.addValidatorMessage('min', (err, field) =>
      this.translate.instant('FORM.VALIDATION.MIN', { min: field.templateOptions.min })
    );
    this.formlyConfig.addValidatorMessage('max', (err, field) =>
      this.translate.instant('FORM.VALIDATION.MAX', { max: field.templateOptions.max })
    );
  }

  onPaste(event: ClipboardEvent) {
    return event.clipboardData
      .getData('text')
      .trim()
      .replace(/_|\s|\.|-|\/|\(|\)|\:|\+|,|@|\[|\]|'|"/gm, '');
  }

  public addPatternValidator(
    validatorName: string,
    pattern: RegExp = /^[0-9]+([,.]\d{1,2})?$/, // Default to strict decimal validation
    errorMessageKey: string
  ) {
    this.formlyConfig.addValidatorMessage(validatorName, (err, field) =>
      this.translate.instant(errorMessageKey)
    );
    this.formlyConfig.setValidator({
      name: validatorName,
      validation: (c: AbstractControl) => {
        // Return ValidationErrors object when invalid, null when valid
        return c.value && !pattern.test(c.value) ? { [validatorName]: true } : null;
      },
    });
  }
}
