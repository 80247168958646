import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { Client, ClientGroup } from '../../../model';
import { ResetClient, SelectClient } from './client.actions';
import { ClientModel, SelectionInfo } from './client.model';

@State<ClientModel>({
  name: 'client',
  defaults: null,
})
@Injectable()
export class ClientState {
  private static translateService: TranslateService;

  constructor(private translateService: TranslateService) {
    this.translateService = translateService;
  }

  private static hasMatchingGroup(state: ClientModel, userGroups: ClientGroup[]): boolean {
    return userGroups.some((c) => state.clientGroupIds.some((cg) => cg === c.id));
  }

  private static findSingleMatchingGroup(
    state: ClientModel,
    userGroups: ClientGroup[]
  ): ClientGroup {
    return userGroups.find((c) => state.clientGroupIds.some((cg) => cg === c.id));
  }

  private static findSingleMatchingClient(state: ClientModel, userGroups: ClientGroup[]): Client {
    if (!this.hasMatchingGroup(state, userGroups)) {
      return null;
    }
    const group = this.findSingleMatchingGroup(state, userGroups);
    return group.clients.find((c) => state.clientIds.some((cm) => cm === c.id));
  }

  // Dynamic Selectors
  static needsReset(groups: ClientGroup[]) {
    return createSelector([ClientState], (state: ClientModel) => {
      if (!state) {
        return true;
      }
      if (state.clientIds && state.clientIds.includes('*')) {
        // If stored id is not matching anymore, reset client group => This means new user or new rights
        if (state.clientGroupIds && !this.hasMatchingGroup(state, groups)) {
          return true;
        } else if (
          groups.length === 1 &&
          this.findSingleMatchingGroup(state, groups).clients.length === 1
        ) {
          // When we detect that there's only one group with one client, but the client isn't selected
          return true;
        }
      } else if (
        !state.clientGroupIds ||
        !this.hasMatchingGroup(state, groups) ||
        !this.findSingleMatchingClient(state, groups)
      ) {
        // If stored id is not matching anymore, reset client => This means new user or new rights
        return true;
      }
      return false;
    });
  }

  static selectionInfo(groups: ClientGroup[]) {
    return createSelector([ClientState], (state: ClientModel) => {
      // If a SuperAdmin logs out, then clientModel is empty or first login.
      if (!state || !groups) {
        return null;
      }

      const result = new SelectionInfo();

      // This means that a superAdmin is logging in, because the clientModel exist but there is no group or client selected
      if (!state.clientIds && !state.clientGroupIds) {
        result.isAllSelected = true;
        result.selectedName = 'MAIN_WRAPPER.ALL';
        result.selectedNameNeedsTranslate = true;
        // This means all groups are selected
      } else if (
        state.clientGroupIds &&
        state.clientGroupIds.length > 0 &&
        state.clientIds === null
      ) {
        result.isAllGroupsSelected = true;
        result.selectedName = 'MAIN_WRAPPER.ALL_GROUPS';
        result.selectedNameNeedsTranslate = true;
        // This means all companies are selected
      } else if (state.clientGroupIds === null && state.clientIds && state.clientIds.length > 0) {
        result.selectedName = 'MAIN_WRAPPER.ALL_COMPANIES';
        result.selectedNameNeedsTranslate = true;
        // This means that a group is selected without a selected client
      } else if (state.clientIds.includes('*') && this.hasMatchingGroup(state, groups)) {
        // Take client group from the groups
        result.isClientGroupSelected = true;
        const clientGroup = this.findSingleMatchingGroup(state, groups);
        result.selectedClientGroupId = clientGroup.id;
        result.selectedName = clientGroup.name;
        // This is normally for a client that is selected
      } else if (this.findSingleMatchingClient(state, groups)) {
        // Take client from the previous login
        result.isClientSelected = true;
        result.isClientGroupSelected = true;
        result.selectedClientGroupId = this.findSingleMatchingGroup(state, groups).id;
        const client = this.findSingleMatchingClient(state, groups);
        result.selectedName = client?.name;
        result.selectedClientId = client?.id;
      }

      return result;
    });
  }

  // Actions
  @Action(SelectClient)
  selectClient(ctx: StateContext<ClientModel>, action: SelectClient) {
    ctx.setState({ ...action.clientModel });
  }

  @Action(ResetClient)
  resetClient(ctx: StateContext<ClientModel>) {
    ctx.setState(null);
  }
}
