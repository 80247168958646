import { Injectable } from '@angular/core';
import { InvoiceFlow } from 'src/app/model/enums';
import { LoggerService } from './logger.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';

/**
 * Service for handling invoice flow display properties like colors and custom names.
 * This service no longer handles flow calculation or determination,
 * as that is now handled by the backend.
 */
@Injectable()
export class InvoiceFlowService {
  // Flow priorities from highest to lowest - used only for validation in development mode
  private readonly FLOW_PRIORITIES = [
    InvoiceFlow.JuridicalDebtCollection,
    InvoiceFlow.DebtCollectionPaymentPlan,
    InvoiceFlow.DebtCollection,
    InvoiceFlow.SignedLetter,
    InvoiceFlow.PaymentPlan,
    InvoiceFlow.Reminder,
    InvoiceFlow.Normal,
    InvoiceFlow.WriteOff,
    InvoiceFlow.Paid,
  ];

  // Client-specific custom flow mappings as objects keyed by enum values
  private flowStateMapping: Record<number, string> = {};
  private flowColorMapping: Record<number, string> = {};
  private customMappingsLoaded = false;

  constructor(
    private logger: LoggerService,
    private settingsService: SettingsService
  ) {
    // Only run validation in development mode
    if (!environment.production) {
      this.validateFlowPriorities();
    }

    // Load custom flow mappings
    this.loadCustomFlowMappings();
  }

  /**
   * Loads custom flow mappings from the settings service
   */
  private async loadCustomFlowMappings(): Promise<void> {
    try {
      const flowStateMapping = await this.settingsService.getFlowStateMappings();

      // Convert array mappings to objects keyed by enum values
      // Assuming the array index corresponds to the flow enum value
      flowStateMapping.flowMappings.forEach((mapping, index) => {
        this.flowStateMapping[index] = mapping.state;
        this.flowColorMapping[index] = mapping.color;
      });

      // Only log in development mode
      if (!environment.production) {
        this.logger.debug('Loaded custom flow mappings:', {
          mappings: Object.keys(InvoiceFlow)
            .filter((key) => !isNaN(Number(key)))
            .map((key) => {
              const flow = Number(key);
              return {
                flow,
                enumName: InvoiceFlow[flow],
                customName: this.flowStateMapping[flow],
              };
            }),
        });
      }

      this.customMappingsLoaded = true;
    } catch (error) {
      this.logger.error('Error loading custom flow mappings, using defaults', { error });
      // If loading fails, continue with the default enum names
      this.initializeDefaultMappings();
    }
  }

  /**
   * Initialize default mappings using enum values
   */
  private initializeDefaultMappings(): void {
    // Use objects instead of arrays for default mappings
    Object.keys(InvoiceFlow)
      .filter((key) => !isNaN(Number(key)))
      .forEach((key) => {
        const flow = Number(key);
        this.flowStateMapping[flow] = InvoiceFlow[flow];
      });

    // Default colors as object mapping
    this.flowColorMapping = {
      [InvoiceFlow.Normal]: '#4CAF50', // Green
      [InvoiceFlow.Paid]: '#9E9E9E', // Grey
      [InvoiceFlow.WriteOff]: '#9E9E9E', // Grey
      [InvoiceFlow.Reminder]: '#FF9800', // Orange
      [InvoiceFlow.PaymentPlan]: '#E91E63', // Pink
      [InvoiceFlow.SignedLetter]: '#FF5722', // Salmon
      [InvoiceFlow.DebtCollection]: '#2196F3', // Blue
      [InvoiceFlow.DebtCollectionPaymentPlan]: '#03A9F4', // Light Blue
      [InvoiceFlow.JuridicalDebtCollection]: '#F44336', // Red
    };

    this.customMappingsLoaded = true;
  }

  /**
   * Get the custom display name for a flow
   * @param flow The invoice flow enum value
   * @returns The custom name or the enum name if no custom mapping
   */
  getFlowDisplayName(flow: InvoiceFlow): string {
    if (!this.customMappingsLoaded || flow === undefined || flow === null) {
      return flow !== undefined && flow !== null ? InvoiceFlow[flow] : 'Unknown'; // Fallback to enum name or Unknown
    }

    const customName = this.flowStateMapping[flow];
    const enumName = InvoiceFlow[flow];

    // Only log in development mode
    if (!environment.production) {
      this.logger.debug(
        `GetFlowDisplayName: ${flow} -> Custom: "${customName}", Enum: "${enumName}"`
      );
    }

    return customName || enumName;
  }

  /**
   * Get the custom color for a flow
   * @param flow The invoice flow enum value
   * @returns The custom color or a default color
   */
  getFlowColor(flow: InvoiceFlow): string {
    if (!this.customMappingsLoaded || flow === undefined || flow === null) {
      return '#9E9E9E'; // Default grey
    }

    return this.flowColorMapping[flow] || '#9E9E9E';
  }

  /**
   * Ensures the custom mappings are loaded
   * @returns Promise that resolves when mappings are loaded
   */
  async ensureCustomMappingsLoaded(): Promise<void> {
    if (!this.customMappingsLoaded) {
      await this.loadCustomFlowMappings();
    }
  }

  /**
   * Validates that all InvoiceFlow enum values are included in the FLOW_PRIORITIES array
   * This helps catch issues when new flow values are added to the enum
   * Only used in development mode
   */
  private validateFlowPriorities(): void {
    // Get all numeric enum values
    const enumValues = Object.keys(InvoiceFlow)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => Number(key));

    // Check if any enum values are missing from the priorities array
    const missingValues = enumValues.filter((value) => !this.FLOW_PRIORITIES.includes(value));

    if (missingValues.length > 0) {
      const missingNames = missingValues.map((value) => InvoiceFlow[value]);
      this.logger.error('Missing InvoiceFlow values in FLOW_PRIORITIES', {
        missingValues: missingNames,
      });

      throw new Error(`Missing InvoiceFlow values in FLOW_PRIORITIES: ${missingNames.join(', ')}`);
    }
  }
}
