<div class="row bg-white my-3 p-4 rounded shadow-sm" *ngIf="paymentPlan">
  <div class="col">
    <!-- Title row -->
    <div class="row mb-2">
      <div class="col">
        <h3 class="text-uppercase light-small-text mb-0" translate>PAYMENTPLANDETAIL.TITLE</h3>
      </div>
    </div>

    <!-- Buttons row -->
    <div class="row mb-3">
      <!-- Download button on the left -->
      <div class="col">
        <a
          class="btn btn-success"
          [href]="getSignedPaymentPlanUrl(paymentPlan.id)"
          target="_blank"
          *ngIf="paymentPlan.mandateInfo && paymentPlan.mandateInfo.mandateIsSigned"
        >
          {{ 'PAYMENTPLAN.SUCCES.SIGNED.DOWNLOAD' | translate }}
        </a>

        <button
          class="btn btn-primary ml-2"
          *ngIf="!alwaysShowPaymentTable"
          (click)="showPaymentTable = !showPaymentTable"
        >
          <span *ngIf="!showPaymentTable" translate>PAYMENTPLANDETAIL.SHOWPAYMENTTABLE</span>
          <span *ngIf="showPaymentTable" translate>PAYMENTPLANDETAIL.HIDEPAYMENTTABLE</span>
        </button>
      </div>

      <!-- Delete button on the right -->
      <div class="col-auto" *ngIf="!inCreation">
        <div *ngxPermissionsOnly="[Permission.Client_Admin, Permission.SuperAdmin] | permission">
          <button *ngIf="!deleting" class="btn btn-danger" (click)="deletePaymentPlanDialog.show()">
            <span translate>PAYMENTPLANDETAIL.REMOVE_PAYMENTPLAN</span>
          </button>
          <button *ngIf="deleting" class="btn btn-danger" disabled type="button">
            <span class="spinner-border spinner-border-sm" role="status"></span>
          </button>
        </div>
      </div>
    </div>

    <!-- Main content area -->
    <div class="row">
      <div class="col-12">
        <div class="row">
          <!-- Left column: Total amount and Paid amount - adjusted for small screens -->
          <div class="col-12 col-sm-6 col-md-4">
            <!-- Total amount -->
            <div class="mb-3">
              <div>{{ 'PAYMENTPLANDETAIL.TOTALAMOUNT' | translate }}:</div>
              <div>
                <span class="total-amount">{{
                  paymentPlan.totalToBePaidAmount | currencyFormat
                }}</span>
              </div>
            </div>

            <!-- Paid amount -->
            <div class="mb-3" *ngIf="!inCreation">
              <div>{{ 'PAYMENTPLANDETAIL.PAIDAMOUNT' | translate }}:</div>
              <div>
                <span>{{ paymentPlan.totalPaidAmount | currencyFormat }}</span>
              </div>
            </div>
          </div>

          <!-- Middle column: Interval and Signature info - adjusted for small screens -->
          <div class="col-12 col-sm-6 col-md-4">
            <!-- Interval -->
            <div class="d-flex flex-wrap align-items-center mb-3">
              <fa-icon class="mr-2" [icon]="['far', 'calendar-alt']"></fa-icon>
              <span>{{ 'PAYMENTPLANDETAIL.PAYMENTINTERVAL' | translate }}:</span>
              <span class="ml-2">
                <span *ngIf="paymentPlan.paymentInterval === PaymentInterval.Weekly">{{
                  'PAYMENTPLANDETAIL.WEEKLY' | translate
                }}</span>
                <span *ngIf="paymentPlan.paymentInterval === PaymentInterval.Monthly">{{
                  'PAYMENTPLANDETAIL.MONTHLY' | translate
                }}</span>
              </span>
            </div>

            <!-- Signature status -->
            <div
              class="d-flex flex-wrap align-items-center mb-3"
              *ngIf="!inCreation && paymentPlan.mandateInfo"
            >
              <fa-icon class="mr-2" [icon]="['fas', 'file-signature']"></fa-icon>
              <span>{{ 'PAYMENTPLANDETAIL.ISSIGNED' | translate }}:</span>
              <div
                class="tag ml-2"
                [class.green]="paymentPlan.mandateInfo.mandateIsSigned"
                [class.red]="!paymentPlan.mandateInfo.mandateIsSigned"
              >
                <span *ngIf="paymentPlan.mandateInfo.mandateIsSigned">{{
                  'PAYMENTPLANDETAIL.YES' | translate
                }}</span>
                <span *ngIf="!paymentPlan.mandateInfo.mandateIsSigned">{{
                  'PAYMENTPLANDETAIL.NO' | translate
                }}</span>
              </div>
            </div>

            <!-- Signature date -->
            <div
              class="d-flex flex-wrap align-items-center mb-3"
              *ngIf="
                !inCreation && paymentPlan.mandateInfo && paymentPlan.mandateInfo.mandateIsSigned
              "
            >
              <fa-icon class="mr-2" [icon]="['fas', 'file-signature']"></fa-icon>
              <span>{{ 'PAYMENTPLANDETAIL.DATESIGNED' | translate }}:</span>
              <span class="ml-2">{{
                paymentPlan.mandateInfo.signDate | amLocale : 'nl-be' | amDateFormat : 'L'
              }}</span>
            </div>
          </div>

          <!-- Right column: Empty now that download button has moved -->
          <div class="col-md-4 d-none d-md-block"></div>
        </div>
      </div>
    </div>

    <!-- Payment table section -->
    <div class="row mt-3" *ngIf="showPaymentTable || alwaysShowPaymentTable">
      <div class="col">
        <!-- Desktop table - hidden on small screens -->
        <div class="d-none d-md-block">
          <p-table
            [autoLayout]="false"
            [value]="paymentPlan.items"
            styleClass="custom-table mb-0"
            [resizableColumns]="false"
            [scrollable]="true"
            [scrollHeight]="'unset'"
            [style]="{ width: '100%', fontFamily: 'inherit' }"
          >
            <ng-template pTemplate="header">
              <tr>
                <th translate>PAYMENTPLANDETAIL.TABLE.DATE</th>
                <th translate>PAYMENTPLANDETAIL.TABLE.REMAINING</th>
                <th translate>PAYMENTPLANDETAIL.TABLE.CAPITAL</th>
                <th translate>PAYMENTPLANDETAIL.TABLE.INTREST</th>
                <th translate>PAYMENTPLANDETAIL.TABLE.COSTS</th>
                <th translate>PAYMENTPLANDETAIL.TABLE.AMOUNT</th>
                <th *ngIf="!inCreation" translate>PAYMENTPLANDETAIL.TABLE.AMOUNTPAID</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr
                [ngClass]="{
                  danger:
                    item.paymentDueDateHasPassed &&
                    item.amount.amountToBePaid > item.amount.amountPaid
                }"
              >
                <td>
                  <span>
                    {{ item.paymentDueDate | amLocale : 'nl-be' | amDateFormat : 'L' }}
                  </span>
                </td>
                <td>
                  <div>
                    {{ item.amount.dossierRemainingTotal | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ item.amount.invoiceAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ item.amount.interestAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ item.amount.costsAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ item.amount.amountToBePaid | currencyFormat }}
                  </div>
                </td>
                <td *ngIf="!inCreation">
                  <div>
                    {{ item.amount.amountPaid | currencyFormat }}
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td>
                  <strong>{{ 'PAYMENTPLANDETAIL.TABLE.TOTAL' | translate }}</strong>
                </td>
                <td>
                  <div>
                    {{ paymentPlan.totalAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ paymentPlan.totalInvoiceAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ paymentPlan.totalInterestAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ paymentPlan.totalCostsAmount | currencyFormat }}
                  </div>
                </td>
                <td>
                  <div>
                    {{ paymentPlan.totalToBePaidAmount | currencyFormat }}
                  </div>
                </td>
                <td *ngIf="!inCreation">
                  <div>
                    {{ paymentPlan.totalPaidAmount | currencyFormat }}
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <!-- Mobile card layout - visible only on small screens -->
        <div class="payment-cards-container d-md-none">
          <!-- Payment Plan Items -->
          <div
            class="payment-card"
            *ngFor="let item of paymentPlan.items"
            [class.danger-card]="
              item.paymentDueDateHasPassed && item.amount.amountToBePaid > item.amount.amountPaid
            "
          >
            <div class="payment-card-header">
              <span class="date-badge">
                {{ item.paymentDueDate | amLocale : 'nl-be' | amDateFormat : 'L' }}
              </span>
            </div>
            <div class="payment-card-body">
              <div class="payment-row payment-highlight">
                <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.AMOUNT</span>
                <span class="payment-value">{{ item.amount.amountToBePaid | currencyFormat }}</span>
              </div>

              <div class="payment-info-rows">
                <div class="payment-row" *ngIf="!inCreation">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.AMOUNTPAID</span>
                  <span class="payment-value">{{ item.amount.amountPaid | currencyFormat }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.REMAINING</span>
                  <span class="payment-value">{{
                    item.amount.dossierRemainingTotal | currencyFormat
                  }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.CAPITAL</span>
                  <span class="payment-value">{{
                    item.amount.invoiceAmount | currencyFormat
                  }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.INTREST</span>
                  <span class="payment-value">{{
                    item.amount.interestAmount | currencyFormat
                  }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.COSTS</span>
                  <span class="payment-value">{{ item.amount.costsAmount | currencyFormat }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Total card -->
          <div class="payment-card payment-total-card">
            <div class="payment-card-header">
              <span class="total-badge" translate>PAYMENTPLANDETAIL.TABLE.TOTAL</span>
            </div>
            <div class="payment-card-body">
              <div class="payment-row payment-highlight">
                <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.AMOUNT</span>
                <span class="payment-value">{{
                  paymentPlan.totalToBePaidAmount | currencyFormat
                }}</span>
              </div>

              <div class="payment-info-rows">
                <div class="payment-row" *ngIf="!inCreation">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.AMOUNTPAID</span>
                  <span class="payment-value">{{
                    paymentPlan.totalPaidAmount | currencyFormat
                  }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.REMAINING</span>
                  <span class="payment-value">{{ paymentPlan.totalAmount | currencyFormat }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.CAPITAL</span>
                  <span class="payment-value">{{
                    paymentPlan.totalInvoiceAmount | currencyFormat
                  }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.INTREST</span>
                  <span class="payment-value">{{
                    paymentPlan.totalInterestAmount | currencyFormat
                  }}</span>
                </div>
                <div class="payment-row">
                  <span class="payment-label" translate>PAYMENTPLANDETAIL.TABLE.COSTS</span>
                  <span class="payment-value">{{
                    paymentPlan.totalCostsAmount | currencyFormat
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- delete paymentplan dialog -->
<app-confirm-dialog
  #deletePaymentPlanDialog
  [header]="'GENERAL.CONFIRM' | translate"
  [isWithNote]="true"
  (approved)="deletePaymentPlan($event)"
  [dialogText]="'PAYMENTPLAN.CONFIRM_DELETE' | translate"
></app-confirm-dialog>
