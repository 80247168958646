export enum InvoiceFlow {
  Normal,
  Paid,
  WriteOff,
  Reminder,
  PaymentPlan,
  SignedLetter,
  DebtCollection,
  DebtCollectionPaymentPlan,
  JuridicalDebtCollection
}
