<p-calendar
  [(ngModel)]="value"
  (onSelect)="dateWasUpdated()"
  (onClearClick)="dateWasUpdated()"
  (onTodayClick)="dateWasUpdated()"
  (onInput)="dateWasUpdated()"
  [placeholder]="placeholder"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [dateFormat]="'dd/mm/yy'"
  showButtonBar="true"
  [inputStyleClass]="inputStyleClass"
  [baseZIndex]="10000"
  appendTo="body"
  [monthNavigator]="true"
  [yearNavigator]="true"
>
</p-calendar>
