import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {take} from 'rxjs/operators';
import {CompanyType, Country, CreateVision2PayDto, Language} from 'src/app/model';
import {GraydonService} from 'src/app/modules/main/services/graydon.service';
import {CompanyLabelPipe, VatNumberPipe} from 'src/app/modules/shared/pipes';
import {ClientService} from 'src/app/modules/shared/services/client.service';
import {PreferenceService} from 'src/app/modules/shared/services/preference.service';
import {FormlyService} from "../../../ui-form/formly.service";

@Component({
  selector: 'app-register-vision2pay-client',
  templateUrl: './register-vision2pay-client.component.html',
  styleUrls: ['./register-vision2pay-client.component.scss']
})
export class RegisterVision2PayClientComponent implements OnInit {
  public form: UntypedFormGroup;
  public fields: FormlyFieldConfig[];
  public formData: CreateVision2PayDto;
  public submitting: boolean;
  public showError: boolean;
  public errorContent: string;
  public currentLanguage: Language;
  @Input() languageCode: string;
  @Input() skipFlowCreation: boolean;
  @Input() currentClientGroupId: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;

  constructor(
    private clientService: ClientService,
    private formlyService: FormlyService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private preferenceService: PreferenceService,
    private store: Store,
    private router: Router,
    private graydonService: GraydonService,
    private companyLabelPipe: CompanyLabelPipe,
    private vatNumberPipe: VatNumberPipe) {

  }

  ngOnInit() {
    switch (this.languageCode.toLowerCase()) {
      case ('fr-be'):
        this.currentLanguage = Language.French;
        break;
      case ('nl-be'):
      default:
        this.currentLanguage = Language.Dutch;
        break;
      case ('en-be'):
        this.currentLanguage = Language.English;
        break;
    }
    this.preferenceService.setPreferredLanguage(this.currentLanguage)
      .pipe(take(1))
      .subscribe(() => {
        this.formData = new CreateVision2PayDto(this.currentLanguage,
          this.firstName,
          this.lastName,
          this.email,
          this.skipFlowCreation,
          this.currentClientGroupId);
        this.buildForm();
      });
  }

  buildForm() {
    this.form = new UntypedFormGroup({});
    this.formlyService.addShouldNotBeGreaterThanValidator('fines',
      'REGISTERVISION2PAY.FORM.FINEVALIDATION',
      'minimumFine',
      'maximumFine');
    this.fields = [
      this.formlyService.createRow(
        [
          this.formlyService.createHorizontalLine()
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createTitle(this.translateService.instant('REGISTERVISION2PAY.FORM.USERDATA'))
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createRequiredInput('firstName', 'REGISTERVISION2PAY.FORM.FIRSTNAME', 'col'),
          this.formlyService.createRequiredInput('lastName', 'REGISTERVISION2PAY.FORM.LASTNAME', 'col')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createRequiredPhoneNumberInput('phone', 'REGISTERVISION2PAY.FORM.PHONE', 'col')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createRequiredMail('email', 'REGISTERVISION2PAY.FORM.EMAIL', 'col')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createHorizontalLine()
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createTitle(this.translateService.instant('REGISTERVISION2PAY.FORM.COMPANYDATA'))
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createVatNumberAutoComplete(
            'vatNumber.value',
            'REGISTERVISION2PAY.FORM.VATNUMBER',
            'col',
            this.graydonService.getCompanySuggestions.bind(this.graydonService),
            (x) => this.vatNumberPipe.transform(x.vatNumber),
            (x) => {
              if (x.companyType === CompanyType.None || x.companyType === CompanyType.NaturalPerson ||
                !this.companyLabelPipe.transform(x.companyType)) {
                return `${x.name} - ${x.postalCode} ${x.place}`;
              }
              const companyTypeKey = this.companyLabelPipe.transform(x.companyType);
              return `${x.name} ${this.translateService.instant(companyTypeKey)} - ${x.postalCode} ${x.place}`;
            }),
          this.formlyService.createRequiredAccountNumber('accountNumber',
            'REGISTERVISION2PAY.FORM.ACCOUNTNUMBER',
            'col')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createRequiredAutoCompleteInput(
            'name',
            'REGISTERVISION2PAY.FORM.COMPANYNAME',
            'col',
            this.graydonService.getCompanySuggestions.bind(this.graydonService),
            null,
            null,
            null,
            null,
            (x) => {
              if (x.companyType === CompanyType.None || x.companyType === CompanyType.NaturalPerson ||
                !this.companyLabelPipe.transform(x.companyType)) {
                return `${x.name}`;
              }
              const companyTypeKey = this.companyLabelPipe.transform(x.companyType);
              return `${x.name} ${this.translateService.instant(companyTypeKey)}`;
            },
            (x) => `${this.vatNumberPipe.transform(x.vatNumber)} - ${x.postalCode} ${x.place}`),
          // this.formlyService.createRequiredInput('name', 'REGISTERVISION2PAY.FORM.COMPANYNAME', 'col'),
          this.formlyService.createDropdownFromEnum('companyType', 'REGISTERVISION2PAY.FORM.COMPANYTYPE',
            CompanyType, [CompanyType.None], 'COMPANYTYPE', 'col')
        ]),

      this.formlyService.createRow(
        [
          this.formlyService.createHorizontalLine()
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createTitle(this.translateService.instant('REGISTERVISION2PAY.FORM.ADDRESS'))
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createRequiredInput('street', 'REGISTERVISION2PAY.FORM.STREET', 'col'),
          this.formlyService.createRequiredInput('houseNumber', 'REGISTERVISION2PAY.FORM.HOUSENUMBER', 'col')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createRequiredInput('postalCode', 'REGISTERVISION2PAY.FORM.POSTALCODE', 'col'),
          this.formlyService.createRequiredInput('place', 'REGISTERVISION2PAY.FORM.PLACE', 'col')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createDropdownFromEnum('country',
            'REGISTERVISION2PAY.FORM.COUNTRY',
            Country,
            [Country.Netherlands, Country.France, Country.Germany, Country.Spain, Country.Italy],
            'country',
            'col')
        ]
      ),
      this.formlyService.createRow(
        [
          this.formlyService.createHorizontalLine()
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createTitle(this.translateService.instant('REGISTERVISION2PAY.FORM.PAYMENTDATA'))
        ]),
      this.formlyService.createRow(
        [
          FormlyService.AddIconRight(
            this.formlyService.createRequiredNumberInput('b2BInterestPercentage',
              'REGISTERVISION2PAY.FORM.INTERESTPERCENTAGE_B2B',
              'col',
              0,
              30),
            'fas fa-percent')
        ]),
      this.formlyService.createRow(
        [
          FormlyService.AddIconRight(
            this.formlyService.createRequiredNumberInput('b2CInterestPercentage',
              'REGISTERVISION2PAY.FORM.INTERESTPERCENTAGE_B2C',
              'col',
              0,
              30),
            'fas fa-percent')
        ]),
      this.formlyService.createRow(
        [
          FormlyService.HideWhen(
            FormlyService.AddIconRight(
              this.formlyService.createRequiredNumberInput('percentagefine', 'REGISTERVISION2PAY.FORM.FINE', 'col', 0),
              'fas fa-percent'),
            '!model.fineIsPercentage'),
          FormlyService.HideWhen(
            FormlyService.AddIconLeft(
              this.formlyService.createRequiredNumberInput('fine', 'REGISTERVISION2PAY.FORM.FINE', 'col', 40),
              'fas fa-euro-sign'),
            'model.fineIsPercentage'),
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createCheckbox('fineIsPercentage', 'REGISTERVISION2PAY.FORM.FINEISPERCENTAGE', 'col')
        ]),
      this.formlyService.createRow(
        [
          FormlyService.HideWhen(
            FormlyService.AddIconLeft(
              this.formlyService.createRequiredNumberInput('minimumFine',
                'REGISTERVISION2PAY.FORM.MINIMUMFINE',
                'col',
                40,
                null,
                ['fines']),
              'fas fa-euro-sign'),
            '!model.fineIsPercentage')
        ]),
      this.formlyService.createRow(
        [
          FormlyService.HideWhen(
            FormlyService.AddIconLeft(
              this.formlyService.createNumberInput('maximumFine',
                'REGISTERVISION2PAY.FORM.MAXIMUMFINE',
                'col',
                0,
                null,
                ['fines']),
              'fas fa-euro-sign'),
            '!model.fineIsPercentage')
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createHorizontalLine()
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createDownloadLink('REGISTERVISION2PAY.FORM.DOWNLOADTERMS', this.getTermsDownloadLink())
        ]),
      this.formlyService.createRow(
        [
          this.formlyService.createAgreedRequiredCheckbox('agreedToTerms',
            'REGISTERVISION2PAY.FORM.AGREEDTOTERMSANDCONDITIONS',
            'col',
            'FORM.VALIDATION.AGREETOTERMS')
        ]),
    ];
  }

  async registerClient() {
    if (!this.form.valid) {
      return;
    }
    this.showError = false;
    this.submitting = true;
    try {
      await this.clientService.createVision2PayClient(this.formData.prepareDto());
      this.submitting = false;
      await this.router.navigate(['ext', 'new-vision2pay-success'], { queryParams: { email: this.formData.email } });
    } catch (ex) {
      if (ex instanceof HttpErrorResponse && ex.status === 400 && ex.error === 'DuplicateVatNumber') {
        this.errorContent = this.translateService.instant('REGISTERVISION2PAY.DUPLICATEVATNUMBERERROR');
      } else if (ex instanceof HttpErrorResponse && ex.status === 400 && ex.error === 'DuplicateEmailAddress') {
        this.errorContent = this.translateService.instant('REGISTERVISION2PAY.DUPLICATEEMAILERROR');
      } else {
        // Show general error
        this.errorContent = this.translateService.instant('REGISTERVISION2PAY.SOMETHINGWENTWRONG');
      }
      this.showError = true;
      this.submitting = false;
    }
  }

  private getTermsDownloadLink() {
    switch (this.currentLanguage) {
      case Language.Dutch:
      default:
        return 'https://satoca-static.s3.eu-central-1.amazonaws.com/Vision2Pay+-+Samenwerkingsvoorwaarden.pdf';
      case Language.French:
        return 'https://satoca-static.s3.eu-central-1.amazonaws.com/Vision2Pay+-+Samenwerkingsvoorwaarden-fr.pdf';
    }
  }
}
