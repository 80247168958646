import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

// Set up logging based on environment
const logError = (message: string, error: any) => {
  if (environment.production) {
    Sentry.captureException(error);
  } else {
    // In development, only log to console - we don't have access to LoggerService yet
    console.error(`[ERROR] ${message}`, error);
  }
};

if (environment.production) {
  Sentry.init({
    dsn: 'https://5bf9cb47aaf8372f3e70ec2dfe4edbde@o4506224307142656.ingest.sentry.io/4506224605593600',
    tracePropagationTargets: environment.sentryTargets,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: environment.sentryTracesSampleRate, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: environment.sentryReplaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: environment.sentryReplaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // We can't use LoggerService directly here since the app hasn't bootstrapped yet
    logError('Bootstrap error', err);
  });
