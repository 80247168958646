import { ClientFilterModel } from './client-filter.model';

export class PagedFilterModel extends ClientFilterModel {
  skip: number;
  take: number;
  orderBy: string;
  orderAscending: boolean;

  constructor(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderDirection: string,
    clientId?: string,
    clientGroupId?: string,
    skipClientFilter: boolean = false
  ) {
    // Only pass clientId and clientGroupId to super if skipClientFilter is false
    super(skipClientFilter ? undefined : clientId, skipClientFilter ? undefined : clientGroupId);
    this.take = pageSize;
    this.skip = (pageNumber - 1) * pageSize;
    this.orderBy = orderBy;
    this.orderAscending = orderDirection === 'asc';
  }
}
