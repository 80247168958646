import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentPlan, PaymentPlanInterval, PaymentPlanSelection } from 'src/app/model';

@Component({
  selector: 'app-paymentplan-simulation',
  templateUrl: './paymentplan-simulation.component.html',
  styleUrls: ['./paymentplan-simulation.component.scss'],
})
export class PaymentplanSimulationComponent {
  @Input() selection: PaymentPlanSelection;
  @Input() suggestion: PaymentPlan;
  @Input() phoneNumber: string = '';
  @Output() changeRequest = new EventEmitter<void>();
  @Output() confirmRequest = new EventEmitter<PaymentPlan>();

  intervalEnum: any = PaymentPlanInterval;

  constructor() {}

  changeSimulation(): void {
    this.changeRequest.emit();
  }

  confirmPaymentPlan(): void {
    this.confirmRequest.emit(this.suggestion);
  }
}
