import { Debtor } from 'src/app/model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { QueryStateService } from '../../shared/services/query-state.service';
import { LoggerService } from '../../shared/services/logger.service';
import {
  DebtorDetail,
  DebtorFilter,
  ExtraReceiverModel,
  InputFieldSearchDebtor,
  PagedDebtor,
  PauseDebtorModel,
  SearchFilter,
} from 'src/app/model';
import { SwapReceiverModel } from '../../../model/SwapReceiverModel';
import { EmergencyReceiverModel } from '../../../model/emergency-receiver.model';
import { ClientModel } from '../../store/client/client.model';
import { Store } from '@ngxs/store';

@Injectable()
export class DebtorService {
  private debtorUrl = 'debtors';

  constructor(
    private http: HttpClient,
    private queryStateService: QueryStateService,
    private store: Store,
    private logger: LoggerService
  ) {}

  async getDebtor(debtorId: string): Promise<DebtorDetail> {
    const currentClientModel = this.store.selectSnapshot<ClientModel>((state) => state.client);
    let queryScope;

    try {
      // If we're at the group level (clientIds includes '*'), maintain that context
      if (currentClientModel?.clientIds?.includes('*')) {
        queryScope = { clientGroupIds: currentClientModel.clientGroupIds };
        this.logger.debug('Using group-level context for debtor access', {
          debtorId,
          clientGroupIds: currentClientModel.clientGroupIds,
        });
      } else {
        // Otherwise use the standard client parameters
        queryScope = this.queryStateService.addClientParameters({});
        this.logger.debug('Using client-level context for debtor access', {
          debtorId,
          clientIds: currentClientModel?.clientIds,
        });
      }

      return await this.http
        .get<DebtorDetail>(`${this.debtorUrl}/${debtorId}`, { params: queryScope as any })
        .toPromise();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 403) {
        this.logger.warn('Permission denied accessing debtor', {
          debtorId,
          queryScope,
          error: error.message,
          currentContext: currentClientModel?.clientIds?.includes('*') ? 'group' : 'client',
        });
      } else {
        this.logger.error('Error accessing debtor', {
          debtorId,
          queryScope,
          error: error.message,
        });
      }
      throw error;
    }
  }

  async getDebtors(filter: DebtorFilter): Promise<PagedDebtor> {
    const queryScope = this.queryStateService.addClientParameters(filter);
    return this.http.get<PagedDebtor>(this.debtorUrl, { params: queryScope as any }).toPromise();
  }

  async pause(debtorId: string, pauseDebtorModel: PauseDebtorModel): Promise<void> {
    const paramsToSend = { ...this.queryStateService.addClientParameters(pauseDebtorModel) } as any;
    if (pauseDebtorModel.plannedNotificationDate) {
      paramsToSend.plannedNotificationDate =
        pauseDebtorModel.plannedNotificationDate.toDateString();
    }
    return this.http
      .post<void>(`${this.debtorUrl}/${debtorId}/pause`, null, { params: paramsToSend })
      .toPromise();
  }

  async unpause(debtorId: string, note: string): Promise<void> {
    const queryScope = this.queryStateService.addClientParameters({ note } as any);
    const paramsToSend = { ...queryScope } as any;
    return this.http
      .post<void>(`${this.debtorUrl}/${debtorId}/unpause`, null, { params: paramsToSend })
      .toPromise();
  }

  async getDebtorsFromSearchValue(searchFilter: SearchFilter): Promise<InputFieldSearchDebtor[]> {
    const queryScope = this.queryStateService.addClientParameters(searchFilter);
    return await this.http
      .get<InputFieldSearchDebtor[]>(`${this.debtorUrl}/search`, { params: queryScope as any })
      .toPromise();
  }

  async updateDebtor(debtor: DebtorDetail): Promise<void> {
    return this.http.patch<void>(`${this.debtorUrl}/update`, debtor).toPromise();
  }

  async addExtraReceiver(extraReceiver: ExtraReceiverModel): Promise<void> {
    return this.http.patch<void>(`${this.debtorUrl}/extra-receiver`, extraReceiver).toPromise();
  }

  async swapDefaultReceiver(swapReceiver: SwapReceiverModel): Promise<void> {
    return this.http
      .patch<void>(`${this.debtorUrl}/swap-default-receiver`, swapReceiver)
      .toPromise();
  }

  async createEmergencyReceiver(emergencyReceiverModel: EmergencyReceiverModel): Promise<void> {
    return this.http
      .patch<void>(`${this.debtorUrl}/create-emergency-receiver`, emergencyReceiverModel)
      .toPromise();
  }

  async approveDebtor(id: string): Promise<void> {
    const queryScope = this.queryStateService.addClientParameters({});
    return this.http
      .patch<void>(`${this.debtorUrl}/${id}`, { params: queryScope as any })
      .toPromise();
  }
}
