import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceFlow } from 'src/app/model';
import { InvoiceFlowService } from '../../services/invoice-flow.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'invoiceFlow',
})
export class InvoiceFlowPipe implements PipeTransform {
  constructor(
    private invoiceFlowService: InvoiceFlowService,
    private translate: TranslateService
  ) {}

  transform(value: InvoiceFlow, flowStateMappings?: string[]): string {
    // If value is undefined or null
    if (value === undefined || value === null) {
      return 'INVOICEFLOW.UNKNOWN';
    }

    // For backward compatibility, use passed in flowStateMappings if provided
    if (flowStateMappings && flowStateMappings[value]) {
      // Check if the mapping is just the enum name (default)
      if (flowStateMappings[value].replace(/\s/g, '') === InvoiceFlow[value].replace(/\s/g, '')) {
        // Format matches the translation file - just uppercase with no spaces
        return `INVOICEFLOW.${InvoiceFlow[value].toUpperCase()}`;
      }
      return flowStateMappings[value];
    }

    // Otherwise use the service
    const displayName = this.invoiceFlowService.getFlowDisplayName(value);

    // If the display name is just the enum name, return the translation key
    if (displayName.replace(/\s/g, '') === InvoiceFlow[value].replace(/\s/g, '')) {
      // Format matches the translation file - just uppercase with no spaces
      return `INVOICEFLOW.${InvoiceFlow[value].toUpperCase()}`;
    }

    // Return the custom name directly
    return displayName;
  }
}
