import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { User, UserManager } from 'oidc-client';
import { BehaviorSubject, from, fromEventPattern, Observable } from 'rxjs';
import { distinctUntilChanged, mergeMap, take } from 'rxjs/operators';
import { ClientGroup, Permission, UserDetailDto } from 'src/app/model';
import { LivefeedService } from '../../shared/services';
import { PreferenceService } from '../../shared/services';
import { UserService } from '../../shared/services';
import { ClientModel, ClientState, ResetClient, SelectClient } from 'src/app/modules/store';
import { LoggerService } from 'src/app/modules/shared/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject('windowObject') private window: Window,
    private userManager: UserManager,
    private router: Router,
    private userService: UserService,
    private permissionService: NgxPermissionsService,
    private preferenceService: PreferenceService,
    private livefeedService: LivefeedService,
    private store: Store,
    private logger: LoggerService
  ) {
    this.setupSilentRenewal();
  }

  public onLogin: BehaviorSubject<UserDetailDto> = new BehaviorSubject<UserDetailDto>(undefined);
  public onLogout: EventEmitter<void> = new EventEmitter<void>();
  public accessToken: string;

  private user: User;
  private tokenChanges: Observable<User>;

  public async login(): Promise<void> {
    const currentPath = this.window.location.pathname;
    const state =
      currentPath === '/notfound' ? '/' : `${currentPath}${this.window.location.search}`;

    this.logger.debug('Attempting to initiate signinRedirect with state:', { state });

    this.userManager
      .signinRedirect({ state })
      .then(() => {
        this.logger.debug('SigninRedirect initiated successfully.');
      })
      .catch((error) => {
        this.logger.error('SigninRedirect error:', error);
      });
  }

  public async logout() {
    await this.livefeedService.stop();
    await this.resetClientModel();

    // Clear local state
    this.user = null;
    this.accessToken = null;

    // Remove user from storage
    await this.userManager.removeUser();

    // Emit logout event
    this.onLogout.emit();

    // Navigate home and redirect to logout
    await this.router.navigate(['/']);
    await this.userManager.signoutRedirect();
  }

  public isAuthenticated(): boolean {
    return this.user && this.user.access_token && !this.user.expired;
  }

  public async doAuthenticationCheck(): Promise<void> {
    try {
      // If user is not loaded already, get from cache
      if (!this.user) {
        this.user = await this.userManager.getUser();
        if (this.isAuthenticated()) {
          // Trigger login success to get permissions
          this.user.state = `${this.window.location.pathname}${this.window.location.search}`;
          return this.onLoginSuccess(this.user);
        }
      }

      if (this.window.location.hash && this.window.location.hash.indexOf('#id_token=') === 0) {
        // if so, then we are in a sign in callback from Identity
        return this.userManager
          .signinRedirectCallback()
          .then((user: User) => {
            return this.onLoginSuccess(user, false, true);
          })
          .catch((e) => {
            // TODO: investigate why we get this message upon first login :(
            // Investigate here further: https://github.com/IdentityModel/oidc-client-js/issues/648
            // Temp fix
            if (e.message !== 'No matching state found in storage') {
              this.logger.error(e.message);
              this.login();
            }
          });
      } else {
        // check if there is a currently authenticated user
        if (!this.isAuthenticated()) {
          return this.login();
        } else {
          // Already signed in
          return Promise.resolve();
        }
      }
    } catch (e) {
      this.logger.error('Authentication check error:', e);
      return this.login();
    }
  }

  public async onLoginSuccess(user: User, silent = false, loggingIn = false) {
    if (!user) {
      this.logger.error('No user provided to onLoginSuccess');
      return;
    }

    try {
      this.user = user;
      this.accessToken = user.access_token;

      // Get user account data once and reuse it
      const account = await this.userService.getCurrent();

      // Set up client model first
      await this.setClientModel(account);
      await this.livefeedService.start(this.accessToken);

      if (!silent) {
        this.permissionService.loadPermissions(account.permissions.map((x) => x.toString()));
        this.preferenceService.setPreferredLanguage(account.language).pipe(take(1)).subscribe();
        this.onLogin.next(account);

        if (loggingIn) {
          this.cleanupUrlAfterLogin();
          const useRoute = this.user.state || this.extractRouteFromUrl();
          await this.router.navigateByUrl(useRoute || '/');
        }
      }
    } catch (error) {
      this.logger.error('Login success handling failed:', error);
      // Don't call logout if we're already in a silent renewal
      if (!silent) {
        this.user = null;
        this.accessToken = null;
        await this.userManager.removeUser();
        this.onLogout.emit();
        await this.router.navigate(['/']);
      }
      throw error;
    }
  }

  private cleanupUrlAfterLogin(): void {
    // Only clean up if we're on the callback URL
    if (this.window.location.hash && this.window.location.hash.indexOf('#id_token=') === 0) {
      const urlWithoutToken = this.window.location.href.split('#')[0];
      this.window.history.replaceState({}, document.title, urlWithoutToken);
    }
  }

  private onLoginError(type: string, error: any) {
    this.logger.error(`Authentication error (${type}):`, error);
    // Don't trigger login on silent renewal errors
    if (type !== 'Silent renew') {
      this.login();
    }
  }

  private setupSilentRenewal() {
    // create tokenChanges Observable
    this.tokenChanges = fromEventPattern(
      (handler) => {
        this.userManager.events.addAccessTokenExpiring(handler as any);
      },
      (handler) => {
        this.userManager.events.removeAccessTokenExpiring(handler as any);
      }
    ).pipe(
      mergeMap(() => from(this.userManager.signinSilent())),
      distinctUntilChanged()
    );

    // update user and token when changed
    this.tokenChanges.subscribe(
      (user) => this.onLoginSuccess(user, true),
      (error) => this.onLoginError('Silent renew', error)
    );
    this.userManager.events.addSilentRenewError((error) =>
      this.onLoginError('Silent renew', error)
    );
  }

  private async setClientModel(account?: UserDetailDto) {
    if (!account) {
      account = await this.userService.getCurrent();
    }
    const groups = account.clientGroups;

    // Check if the previously stored client model needs a reset
    const needsReset = this.store.selectSnapshot(ClientState.needsReset(groups || []));
    if (needsReset) {
      await this.resetClientModelForUser(account, groups);
    }
  }

  private async resetClientModel() {
    // This means a SuperAdmin is logged in, when he logs out we want to make the clientModel undefined
    // So the next time someone logs in, we check again if the user is SuperAdmin or not
    const clientModel = this.store.selectSnapshot<ClientModel>((state) => state.client);
    if (!clientModel.clientGroupIds && !clientModel.clientIds) {
      this.store.dispatch(new ResetClient());
    }
  }

  private async resetClientModelForUser(account: UserDetailDto, groups: ClientGroup[]) {
    // Check for superAdmin
    if (account.permissions.includes(Permission.SuperAdmin)) {
      this.store.dispatch(new SelectClient(new ClientModel(null, null)));
    } else {
      // If it is not a admin then reset the client
      this.store.dispatch(
        new SelectClient(
          new ClientModel(
            [groups[0].id],
            groups[0].clients.length === 1 ? [groups[0].clients[0].id] : ['*']
          )
        )
      );
      return;
    }
  }

  private extractRouteFromUrl(): string {
    const path = this.window.location.pathname;
    return path === '/notfound' ? '/' : path + this.window.location.search;
  }

  async signinRedirect(state?: string): Promise<void> {
    this.logger.debug('Attempting to initiate signinRedirect with state:', { state });
    try {
      await this.userManager.signinRedirect({ state });
      this.logger.debug('SigninRedirect initiated successfully.');
    } catch (error) {
      this.logger.error('Failed to initiate signinRedirect', { error });
      throw error;
    }
  }
}
